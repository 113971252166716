.NotLoggedIn_loginItem{
    background-color:#1F1F1F;
    padding-top: 16px;
    padding-bottom: 16px;
    border : 2px solid #1F1F1F;
}
.NotLoggedIn_loginItem:hover{
    border-color : rgba(255,255,255,0.5)
}
.NotLoggedIn_loginItem-Header{
    padding-top: 3px;
}