body {
  margin: 0;
  font-family: 'Raleway';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
    background-color: #262626;
    overflow-y:auto;
    overflow-x:hidden;
}
::-webkit-scrollbar{
  width:6px;
  height:6px
}
::-webkit-scrollbar-track {
  background: #1f1f1f;
}
::-webkit-scrollbar-thumb {
  background: #AA0000;
  border-radius: 50px;    
}
::-webkit-scrollbar-thumb:hover {
  background: #F40000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
