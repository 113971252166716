.UploadImage_file-input::-webkit-file-upload-button{
    border:1px solid #FFFFFF75;
    border-radius: 4px;
    padding: 12px;
    background-color: #FFFFFF00;
    color : #FFFFFF;
    font-size: 16px;
}
.UploadImage_file-input{
    color:#FFFFFF
}